import React from 'react';
import '../components/timmi.css'
import { StaticQuery, graphql } from "gatsby"

export default function BookClub(){
    return(
        <section id="bookclub" className="timmiPage">
            <h1 className="pageHeading">bookclub</h1>
        </section>
    )
}
